.accordion-body {
    padding: 0px 0px 0px 10px !important;
}
.accordion-button {
    padding: 10px !important;
}

.accordion-body-table {
    max-height: 7em;
    overflow-y: auto;
}

// .fil-but1 {
//     margin-left: 50px;
// }

.e-accordion .e-tgl-collapse-icon::before {
    content: '\e823';
}

.e-accordion .e-tgl-collapse-icon.e-expand-icon::before {
    content: '\e934';
}

.accordion-button::after {
    background-image: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22currentColor%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%20320%20512%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E) !important;
}

.label-bg {
    background-color: #d1fae4;
}
