.audio-player {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* button {
    margin-top: 10px;
    position: relative;
  } */
  
  .playlist {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border: 1px solid #000;
    border-radius: 0px 0px 5px 5px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow: auto;
    width: 100%;
  }
  
  .playlist li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }

  .playlist li:last-child {
    border-bottom: none;
  }
  
  
  .playlist li.active {
    background-color: #3b6fd0;
    color: #fff;
  }

  .stop-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    outline: inherit;
    padding: 10px;
    margin-left: 5px;
  }
  
  .rhap_header {
    margin-bottom: 10px;
    font-size: 12px;
}

.rhap_time {
  font-size: 12px;
}

/* .rhap_main-controls button{
  font-size: 18px;
}
.rhap_additional-controls button{
  font-size: 18px;
}
.rhap_volume-controls button{
  font-size: 18px;
}
 */
