.dataTableWrapper{
    width: 90%;
}

.buttonWrapper{
    width: 90%;
}

.formWrapper {
    padding-top: 100px !important;
    min-height: 800px !important;
}
.dataTableWrapper .filtersWrap{
    width: 40%;
}
.dataTableWrapper .filtersWrap .multiSelect{
    width: 100%;
    /* margin-top: 10px; */
}
.dataTableWrapper .filtersWrap .addRecords{
    width: 50%;
}
.fameContainer{
    min-height: 625px;
    padding-top: 100px !important;
}
.emailToast{
    color: #2749B2;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 4px 0px;
}

@media (max-width:430px){
    .dataTableWrapper,.dataTableWrapper .filtersWrap{
        display: block!important;
    }
    .dataTableWrapper .filtersWrap{
        width: 100%;
    }
}

.ellipsisColumn {
    width: 200px;
    padding: 2px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .pageTitle {
    color: #2749B2 !important;
    font-size: 18px !important;
    font-weight: 600;
  }
