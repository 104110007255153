.scrollingBar {
  height: 25px;
  margin-bottom: .5rem;
  // background-color: #fff;
}

.scrollingBar span {
  color: #3b6fd0 !important;
  font-weight: 600 !important;
  // margin-right: 100px !important;
}

.loaderModal .modal-content {
  position: absolute;
  display: flex !important;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  color: #000;
  pointer-events: auto;
  background-color: #fff0;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid rgb(0 0 0 / 0%);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  margin-top: 15em;
}

.momentsModal .modal-content {
  height: auto !important;
  padding: 0px 0px 25px 0px !important;
  background-color: #f1f1f1 !important;
}

.slick-prev {
  left: 2% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.quickLinksHeader {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #3b6fd0;
  padding: 55px 0px 0px 15px;
}

.quickLinksCard {
  display: flex;
  margin: 10px;
  border: solid 1px transparent !important;
  border-radius: 5px;
  width: 100px !important;
  height: 100px !important;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  box-shadow: 2px 2px 5px 1px #4671ba;
}

.quickLinksCard:hover {
  border: solid 1px #3b6fd0 !important;
}

.quicklinkcardimage {
  width: 100%;
  height: auto;
}

.sticky-div {
  position: relative;
  margin: 5px;
  text-align: center;
}

.sticky-div img {
  width: 100%;
  height: auto;
}

.feedback-content {
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 300px;
  bottom: 0px !important;
  right: 0px !important;
}


.feedbackContainer {
  position: relative;
  margin-top: 10px;
  border-radius: 5px;
  border: solid 1px transparent !important;
  cursor: pointer;
}

.feedbackContainer:hover {
  border-radius: 5px;
  border: solid 1px #3b6fd0 !important;
  box-shadow: 2px 2px 5px 1px #4671ba;
}

.knowledgeCard {
  padding: 10px;
  width: 300px !important;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  border: solid 1px #eeeeee !important;
  margin: 10px 20px 10px 10px;
  text-align: center;
}

.knowledgeCard:hover {
  border: solid 1px #3b6fd0 !important;
  box-shadow: 2px 2px 5px 1px #4671ba;
}

