.loader .modal-content {
  position: absolute;
  display: flex !important;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff0;
  border: none;
  border-radius: none;
  outline: 0;
  margin-top: 15em;
}

.dataTableWrapper {
  width: 90%;
}

.buttonWrapper{
  width: 90%;
}

.formWrapper {
  padding-top: 100px !important;
  min-height: 800px !important;
}
.dataTableWrapper .filtersWrap{
  width: 40%;
}
.dataTableWrapper .filtersWrap select{
  width: 100%;
}
.dataTableWrapper .filtersWrap button{
  width: 50%;
}
.fameContainer{
  min-height: 625px;
}

.ellipsisColumn {
  width: 200px;
  padding: 2px 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
