
.sidebar {
    background-color: #0b3367;
    color: #fff;
    padding-left: 30px;
    padding-top: 10px;
    height: auto;
    overflow: scroll;
}

.dept {
    margin: 10px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}
.dept:hover{
    color: #ff8b17 !important;

}

.selectedDept {
    color: #ff8b17 !important;
}

