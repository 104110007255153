.slick-prev:before, 
.slick-next:before {
    color: #3b6fd0; 
    font-size: 30px;
    opacity: 1 !important;
}

.programs-slider-container .slick-prev {
    left: 10px;
    z-index: 1;
    // display: block !important;
}

.programs-slider-container .slick-next {
    right: 1px;
    z-index: 1;
    // display: block !important;
}

.programs-slider-container .slick-slide img {
    object-fit: contain !important;
}
.slick-dots li.slick-active button:before, .programs-slider-container .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #3b6fd0;
}

.programs-slider-container .slick-dots li button:before{
    width: 40px;
    height: 40px;
    font-size: 16px;;
}

.slick-dots li button:before {
    color: #3b6fd0;
}