.menu {
    min-height: 4em;
    margin-top: 1em;
    max-width: 50%;
    color: white;
    padding-left: 1em;
    cursor: pointer;
}

a {
    text-decoration: none !important;
}

.menu:hover > .icon {
    color: rgb(90, 238, 90);
}

.ict {
    font-size: 0.85rem;
}
