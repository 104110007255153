.pagetitle {
  color: #2749B2;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.privacyText {
  font-size: 16px !important;
  margin: 12px !important;
}

.privacyContainer {
  min-height: '650px';
  padding-top: 5rem;
  overflow: 'auto';
}

@media (max-width:425px) {
  .privacyContainer {
    padding-top: 11rem !important;
  }
}