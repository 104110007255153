.customRow{
    cursor: pointer;
}
.table-responsive .react-bootstrap-table{
    height: 100%;
    overflow: auto;
}
.table-responsive .react-bootstrap-table .table{
    background-color: transparent!important;
    color: transparent!important;
}
.table-responsive .react-bootstrap-table .table tbody tr{
    cursor: pointer;
    background-color: #FAFAFA;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.4s;
}
.table-responsive .react-bootstrap-table .table tbody tr td{
    color: #3b6fd0;
    padding: 13px 8px;
}
.table-responsive .react-bootstrap-table .table tbody tr:hover {
    background-color: #FFFFFF;
}
.table-responsive .react-bootstrap-table .table tbody tr:hover td{
   background-color: #eee !important;
   color: #3b6fd0 !important
}

.table-responsive table th{
    background-color: #eee !important;
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;
}

.pagination-container{
    text-align: center;
}
.table-responsive .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list{
    display: flex;
    justify-content: end;
}