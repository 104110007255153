@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;600&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    /* overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
        */
}

/* .table-responsive {
    max-height: 70vh;
    min-height: 70vh !important;
    height: 70vh !important;
} */

.custom-button-do {
    background-color: rgb(73, 151, 73);
    border-color: #fff;
    margin-top: 10px;
}

.custom-button-reset {
    margin-top: 10px;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #252424cc;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100 !important;
    opacity: .75 !important;
}

.modal-content {
    /* width:90%; */
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    /* height: 80vh !important; */
    overflow: auto !important;
    padding-bottom: 50px !important;
}

.addCategoryPopup .modal-content {
        background-color: white;
        padding: 25px;
        border-radius: 10px;
        height: 40vh !important;
        overflow: auto !important;
}

.appLauncher .modal-content {
    width:90%;
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    height: 80vh !important;
    overflow: auto !important;
}

.loaderMain {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.customSliderWrap > .swiper-wrapper{
    margin-bottom: 30px !important;
}

.customSliderWrap  .swiper-button-prev{
    bottom: -10px!important;
    top: unset!important;
    left: unset!important;
    right: 20px!important;
    width: auto!important;
}

.customSliderWrap  .swiper-button-prev::after , .customSliderWrap  .swiper-button-next::after{
    font-size: 16px!important;
    font-weight: bolder!important;
    color: #2749B2!important;
}

.customSliderWrap  .swiper-button-next{
    bottom: -10px!important;
    top: unset!important;
    left: unset!important;
    right: 0px!important;
    width: auto!important;
}

.walloffame_wrap {
    min-height: 625px !important;
}

.managePageTitle {
    color: #2749B2 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    text-align: center !important;
    margin-bottom: 1.5rem !important;
}
