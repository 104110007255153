.contactContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
    min-height: 1150px;
    overflow: auto;
}

.contactCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 225px;
    height:225px;
    padding: 32px 10px;
    border-style: solid;
    border-radius: 10px;
    border-color: rgba(167, 167, 167, 0.3);
    background-color: white;
    transition: transform .3s;
    box-shadow: #00000059 0px 5px 15px;
}

.teamName {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.teamMail {
    color: #2749B2;
    font-size: 13px;
    margin-top: 10px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    text-align: center;
}

.teamSpoc {
    color: #a7a7a7;
    margin-top: 10px;
    text-align: center;
}

.title {
    color: #2749B2 !important;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    padding-left: 10px !important;
}

.expandedcard {
    border-color: rgba(167, 167, 167, 0.3);
    background-color: white;
    transition: transform .3s;
    box-shadow: #00000059 0px 5px 15px;
}

@media (max-width:425px) {
    .contactContainer{
        padding-top: 10rem !important;
        margin-left: 0rem !important;
    }
}