.learningCard {
    padding: 10px;
    padding-top: 20px;
    border-radius: 5px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
    height: 150px;
    width: 150px;
    margin: 20px 50px 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: center;
    transition: transform .2s;
    cursor: pointer;
}

.learningCard:hover {
    transform: scale(1.2);
    animation: mymove 3s infinite;
}

.learningCard div {
    margin-top: 10px !important;
}

@keyframes mymove {
    from {background-color: #fff;}
    to {background-color: #3b6fd0;}
  }