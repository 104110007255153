.listViewContainer .docView:not(:last-child)>.listView {
    border-bottom: 1px solid #ccc !important;
}

.docView {
    .listView {
      display: flex;
      align-items: center;
      padding: 10px;
    }
    h6 {
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .cardTitle {
    color: #2749B2;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: left;
  }
