.clientSummary{
    padding: 30px 0px;
    overflow: auto;
    padding-bottom: 50px;
    padding-top: 5rem;
    min-height: 1150px;
    margin-left: 1.5rem;
}
.clientInfoWrapper{
    display: grid;
    grid-template-columns: auto auto auto;
    gap:20px;
}
.clientInfoCard{
    padding: 20px 15px;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 0px 6px #A7A7A78E;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: transform 0.5s;
    background-color: #2749B2;
}
.clientInfoCard:hover{
    transform: scale(1.05);
}
.clientInfoCard h3{
    font-size: 16px;
    margin: 0;
    color: #242424;
}

.pageTitle {
    color: #2749B2 !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;
    text-align: left !important;
}

.clientTitle {
    color: #2749B2 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;
    text-align: center !important;
}

.client_name {
    color: #fff !important;
}

.clientCard {
    display: flex;
    flex-direction: column;
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 20px;
    margin: 20px;
    box-shadow: #00000059 0px 5px 15px;
}
.clientCard a {
    font-size: 14px
}
.clientCard img {
    height: 150px !important;
    width: 150px !important;
    object-fit: scale-down;
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 5px;
    margin: 10px;
    box-shadow: #00000059 0px 5px 15px;
}

.clientCard .clientName {
    font-size: 18px;
    font-weight: bold;
    color: #2749B2;
    margin-top: 10px;
}

.clientCard .clientDescription {
    font-size: 14px;
    position: relative;
    bottom: 0px;
}

@media (max-width:425px) {
    .clientInfoWrapper{
        display: block;
    }
    .clientInfoCard {
        margin-bottom: 10px;
    }
    .clientInfoCard:last-child{
        margin-bottom: 0;
    }
    .clientSummary{
        padding-top: 10rem;
        margin-left: 0rem !important;

    }

}