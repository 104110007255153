.list-with-divs {
  list-style: none;
  // padding: 0;
}

.list-with-divs li {
  margin-bottom: 10px;
  /* Add space between list items */
  position: relative;
  /* Required for positioning the pseudo-element */
  width: 320px;
}

// .list-with-divs li.withEmp::after {
//   content: ''; /* Add content to the pseudo-element */
//   display: block; /* Make it a block-level element */
//   height: 5px; /* Set the height of the divider */
//   background-color: #ccc; /* Change color as needed */
//   position: absolute; /* Position it relative to its parent */
//   // bottom: 0px; /* Adjust position as needed */
//   left: 200px; /* Position it at the left edge of the parent */
//   right: 50px; /* Stretch it to the right edge of the parent */
//   top:50%;
//   z-index: -2 !important;
// }

// .list-with-divs li::before {
//   content: ''; /* Add content to the pseudo-element */
//   display: block; /* Make it a block-level element */
//   height: 5px; /* Set the height of the divider */
//   background-color: #ccc; /* Change color as needed */
//   position: absolute; /* Position it relative to its parent */
//   // bottom: -5px; /* Adjust position as needed */
//   left: -18px; /* Position it at the left edge of the parent */
//   right: 270px; /* Stretch it to the right edge of the parent */
//   top:50%;
//   z-index: -2 !important;
// }

.list-with-divs li.activeElement.rootElement.withEmp::before {
  background: none !important;
  z-index: 0;

}

.list-with-divs li.activeElement.withEmp::before {
  background: red !important;
  z-index: 0;

}

.list-with-divs li.activeElement.withEmp::after {
  background: red !important;
  z-index: 0;

}

.list-with-divs li.activeElement.withoutEmp::before {
  background: none !important;
  z-index: 0;

}

.list-with-divs li.activeElement.withoutEmp::after {
  background: none !important;
  z-index: 0;

}

.activeElement .empCount,
.activeElement .empCountBox {
  border: 2px solid #4b89dc !important;
}

.activeElement .empCount {
  background-color: #4b89dc;
  border: 0px solid #4b89dc !important;
  color: #fff;
}

.empCount {
  background-color: #fff;
  border-radius: 5px !important;
}

.hline {
  border: 2px solid black;
  width: 50px;
  position: absolute;
  top: 55% !important;
  left: -15px;
  z-index: -1;
  background: #fff;
}

.hlinecount {
  border: 1px solid #dadada;
  width: 40px;
  position: absolute;
  top: 55% !important;
  left: 190px;
  z-index: -1;
  background: #fff;
}

.ullist {
  max-height: 700px !important;
  overflow-y: scroll !important;
  margin-bottom: 0px;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.ullist::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.activeElement .hlinecount {
  border: 1px solid #4b89dc !important;
  top: 57% !important;
}

// .test-test {
//   max-height: 900px !important;
//   overflow-y: scroll !important;
// }

.test-test svg {
  position: fixed !important;
}

.popup-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #4b89dc;
  padding: 20px !important;
  border-radius: 0px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 9px;
  left: 50px !important;
  max-width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: end;
}

#popupContent::after {
  content: '';
  position: absolute;
  top: -20px;
  /* Adjusted the position of the arrow */
  left: 20%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #4b89dc transparent;
  /* Changed border-color */
}

.empDetails {
  display: flex;
  flex-direction: column;
  align-items: start;
  border: none !important;
}

.countDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none !important;
  border-left: 1px solid #e1e1e1 !important;
}

.tooltip.bottom>.arrow {
  border-bottom-color: #4b89dc !important;
  border-top-width: 0;
  left: 50%;
  margin-left: -11px;
  top: -11px;
}

.bs-popover-bottom>.popover-arrow::after {
  border-bottom-color: #4b89dc !important;
}

.count {
  text-align: center;
  color: #4b89dc;
  font-size: 16px;
  font-weight: 600;
}

.totalMembers {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.directReports {
  text-align: center;
  font-size: 14px;
}

.empDetails .name {
  font-size: 14px;
  font-weight: 600 !important;
  word-wrap: break-word;
  width: 200px;
  display: block;
}

.empDetails .role {
  font-size: 14px;
  color: #888;
  margin-bottom: 0.5rem !important;
}

.empDetails .department {
  font-size: 14px;
  color: #888;
  word-wrap: break-word;
  width: 200px;
  display: block;
}

.empDetails .emailid {
  font-size: 14px;
  color: #4b89dc;
  word-wrap: break-word;
  width: 200px;
}

.boxName {
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 180px;
}

.boxRole {
  width: 180px;
  color: #888;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}