.footer-section {
    background-color: #414141;
    width: 100%;
    padding-top: 10px;
    padding-left: 100px;
}

.footerHead {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF !important;
}

.footerLink a {
    text-align: left;
    text-decoration: underline;
    font-size: 14px;
    color: #84D0FF !important;

}

.copyrightSection {
    // border-top: 2px solid white;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-right: 50px;
}

.copyrightText {
    text-align: left;
    font-size: 14px;
    color: #FFFFFF;
}

.footerLink a {
    text-decoration: underline !important;
}