#brandpre {
    color: rgb(34, 194, 34) !important;
}
.amenu-1 {
    /* color: hsl(120, 70%, 45%) !important; */
    /* color: '#b80707' !important; */
    /* color: rgb(184, 7, 7) !important; */
    color: rgb(10, 62, 107) !important;
}

.amenu-2 {
    /* color: rgb(29, 107, 10) !important; */
    color: rgb(10, 62, 107) !important;
}

.amenu-3 {
    color: rgb(10, 62, 107) !important;
}
.amenu-4 {
    /* color: rgb(62, 10, 107) !important; */
    /* color: rgb(184, 7, 7) !important; */
    color: rgb(10, 62, 107) !important;
}

.validation-msg {
    color: #ed1313;
}
/* .menu1 {
    margin-right: 10px !important;
}

.menu2 {
    margin-right: 10px;
    
}
.menu3 {
    margin-right: 10px;
    
}
.menu4 {
    margin-right: 10px;
    
} */

.navHeader.active {
    color: #FDD21C !important;
    background-color: #3b6fd0 !important;
}

.navHeader.active>a{
    color: #FDD21C !important;
    background-color: #3b6fd0 !important;
}

.nav-item .dropdown.active {
    color: #FDD21C !important;
    background-color: #3b6fd0 !important;
}

.navHeader:hover, .navHeader a:hover {
    color: #FDD21C !important;
    background-color: #3b6fd0 !important;
}

.navHeader {
    color: #fff !important;
}

.navHeader .dropdown-menu.show {
    max-height: 350px;
    overflow: scroll;
}

.dropdown-toggle.show.nav-link {
    color: #FDD21C !important;
}

.dropdown-toggle.nav-link {
    color: #fff !important;
}

.navDropDown.show.active {
    color: #FDD21C !important;
}

.navDropDown:hover {
    color: #FDD21C !important;
}

.searchBox,
.searchBox:active,
.searchBox:focus {
    border: none !important;
    box-shadow: none !important;
}

.searchBtn,
.searchBtn:focus,
.searchBtn:active {
    border: none !important;
    position: absolute !important;
    z-index: 10 !important;
    right: 0;
}

.tabNavItem .nav-link.active {
    border: none !important;
    border-bottom: 3px solid #FF8B17 !important;
    background: #fff0 !important;
    color: #2749B2 !important;
    font-weight: 600 !important;
}

.navbar-toggler {
    background-color: white !important;
    box-shadow: none !important;
}

.dropdown-item {
    color: #2749B2 !important;
}

.dropdown-item .active,
.dropdown-item:hover {
    color: #2749B2 !important;
}

.dropdown-menu {
    border-radius: 0px !important;
    margin-top: 14px !important;
    padding: 0px !important;
}

.nav-tabs {
    border: none !important;
}

.nav-item .a {
    border-color: none !important;
    background-color: none !important;
}

.popover-content {
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    top: 50px;
    right: 120px;
    width: 300px;
  }
  
  #popoverContent::after {
    content: '';
    position: absolute;
    top: -20px; /* Adjusted the position of the arrow */
    left: 45%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #f9f9f9 transparent; /* Changed border-color */
  }

  .mailDescTextarea {
    overflow-y: auto;
    height: 150px !important;
    resize: none; /* Remove this if you want the user to resize the textarea */
}

.helpCenter:hover {
    color: #FDD21C !important;
    /* color: #3b6fd0 !important; */
    /* background: #fff !important; */
    /* border-color: #fff !important; */
}

.divider {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

a.bg-transparent.menulink.dropdown-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

a.bg-transparent.menulink.dropdown-item.active, a.bg-transparent.menulink.dropdown-item:hover {
    color: #fff !important;
    background-color: #3b6fd0 !important;
}