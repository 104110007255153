@media only screen and (max-width: 767px) {
    .main {
        padding-left: 0px !important;
    }

    p {
        font-size: 12px;
    }

    span {
        font-size: 12px;
    }

    h1 {
        font-size: 24px !important;
    }

    h2 {
        font-size: 18px !important;
    }

    h3 {
        font-size: 18px !important;
    }

    h4 {
        font-size: 16px !important;
    }

    h5 {
        font-size: 16px !important;
    }

    h6 {
        font-size: 14px !important;
    }

    .usersearch {
        padding: 5px 37px 5px 15px !important;
    }
}

.main {
    background-color: #F5F5F5;
}

.navGaps {
    padding: 0px 0px 50px 0px;
}

.slideshow {
    max-width: 800px;
    margin: 0 auto;
}

.slick-slide {
    margin-top: 0px !important;
}

.moments-captured .slick-slide > div:first-of-type {
    padding-bottom: 0px;
  }

.slick-slide img {
    width: 100%;
    /* height: 200px; */
    object-fit: contain;
    padding: 5px;
    display: inline-block !important;
}

.slick-thumb li a img {
    height: 50px !important;
    padding: 15px !important;
}

.slick-arrow {
    display: none !important;
}

.momentsModal .slick-arrow, .brochureSlider .slick-arrow, .newsSlider .slick-arrow, .awardsSlider .slick-arrow {
    display: block !important;
}

.brochureSlider .slick-prev {
left: 1rem !important;
}

.brochureSlider .slick-next {
right: 1.25rem !important;
}

.brochureSlider, .newsSlider, .thoughtsSlider, .awardsSlider {
/* overflow: scroll; */
}

.brochureSlider .slick-slider {
    padding: 0px 50px 0px 25px !important;
}

.newsSlider .slick-slider, .awardsSlider .slick-slider {
    padding: 0px 50px 0px 75px !important;
}

.awardsSlider .slick-slider button::before {
    font-size: 40px !important;
}

.podcastSlider .slick-slider {
    padding: 0px 50px 0px 75px !important;
}
.thoughtsSlider .slick-slider {
    padding: 0px 0px 0px 0px !important;
}

.momentsModal .slick-prev::before,
.momentsModal .slick-next::before {
    color: #3b6fd0 !important;
    opacity: 1 !important;
}

.programs-slider-container .slick-dots li button:before,
.slick-dots li button:before {
    opacity: .7;
}

/* Highlight the active dot */
.programs-slider-container .slick-dots li.slick-active button:before,
.slick-dots li.slick-active button:before {
    /* color: #3b6fd0 !important; */
    opacity: 1;
    /* Color for the active dot */
}

/* Remove hover effect */
.programs-slider-container .slick-dots li button:hover:before,
.slick-dots li button:hover:before,
.programs-slider-container .slick-dots li button:focus:before,
.slick-dots li button:focus:before {
    opacity: .7;
}

.eventsSliderContainer .slick-slider {
    position: relative !important;
  }

.industryCardSilderContiner .slick-slider {
    position: relative !important;
}

.industryCardSilderContiner img {
    object-fit: fill !important;
    padding: 0px !important;
}
.eventsSliderContainer .slick-dots {
    text-align: right !important;
    position: absolute !important;
    bottom: -80px !important;
  }
  
.eventsSliderContainer .slick-dots li button:before {
    color: #3b6fd0 !important;
    font-size: 16px !important;
    text-align: right;
}
@media (min-width: 768px) and (max-width: 991px) {
    span.rightbrand.navbar-brand {
        width: 100% !important;
    }
}

@media (max-width: 991px) {
    .eventsSliderContainer .slick-dots {
        text-align: right !important;
        position: absolute !important;
        bottom: -110px !important;
    }
}
