.hubContainer {
  margin-left: -1rem !important;
  height: auto;
  overflow: auto;
}

.pagetitle {
    color: #2749B2;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
  }

/* Extra Small Devices (Phones, <576px) */
@media (max-width: 575px) {
    .hubContainer {
      padding-top: 140px;
    }
  }
  
  /* Small Devices (Tablets, ≥576px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .hubContainer {
      padding-top: 140px;
    }
  }
  
  /* Medium Devices (Small Laptops, ≥768px) */
  @media (min-width: 768px) and (max-width: 991px) {
    .hubContainer {
      padding-top: 70px;
      max-height: 780px !important;
      overflow: scroll;
    }
  }
  
  /* Large Devices (Laptops, Desktops, ≥992px) */
  @media (min-width: 992px) and (max-width: 1199px) {
    .hubContainer {
      padding-top: 70px;
      max-height: 708px !important;
      overflow: scroll;
    }
  }
  
  /* Extra Large Devices (Large Desktops, ≥1200px) */
  @media (min-width: 1200px) {
    .hubContainer {
      padding-top: 70px;
    }
  }
  