.termsHead {
    color: #2749B2;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    text-align: center;
}
.consentText {
    font-size: 16px !important;
    margin: 12px !important;
}

.consentForm {
    height: 600px;
    overflow: auto;
}
