.pagetitle {
  color: #2749B2;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
}

.consentText {
  font-size: 16px !important;
  margin: 12px !important;
}

.termsContainer {
  min-height: '650px';
  padding-top: 5rem;
  overflow: 'auto';
}

@media (max-width:425px) {
  .termsContainer {
    padding-top: 11rem !important;
  }
}
