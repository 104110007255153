.dropdownSuggestions {
    position: absolute;
    z-index: 1050;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    color: #000 !important;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
}
